// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-js": () => import("./../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-farm-business-bulk-cof-compost-js": () => import("./../src/pages/farm-business/bulk-cof-compost.js" /* webpackChunkName: "component---src-pages-farm-business-bulk-cof-compost-js" */),
  "component---src-pages-farm-business-feed-farm-supplies-js": () => import("./../src/pages/farm-business/feed-farm-supplies.js" /* webpackChunkName: "component---src-pages-farm-business-feed-farm-supplies-js" */),
  "component---src-pages-farm-business-online-products-js": () => import("./../src/pages/farm-business/online-products.js" /* webpackChunkName: "component---src-pages-farm-business-online-products-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-and-press-awards-js": () => import("./../src/pages/news-and-press/awards.js" /* webpackChunkName: "component---src-pages-news-and-press-awards-js" */),
  "component---src-pages-supporters-our-supporters-js": () => import("./../src/pages/supporters/our-supporters.js" /* webpackChunkName: "component---src-pages-supporters-our-supporters-js" */),
  "component---src-pages-visit-us-educational-programs-js": () => import("./../src/pages/visit-us/educational-programs.js" /* webpackChunkName: "component---src-pages-visit-us-educational-programs-js" */),
  "component---src-pages-visit-us-hands-on-education-js": () => import("./../src/pages/visit-us/hands-on-education.js" /* webpackChunkName: "component---src-pages-visit-us-hands-on-education-js" */),
  "component---src-pages-visit-us-private-farm-tours-js": () => import("./../src/pages/visit-us/private-farm-tours.js" /* webpackChunkName: "component---src-pages-visit-us-private-farm-tours-js" */)
}

